
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Button } from 'ant-design-vue';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { EventBus } from '@/helpers/EventBusHelper';
import Offer from '@/models/Offer';
import { Action } from 'vuex-class';

@Component({
    name: 'UnlockOfferButton',
    components: { Button },
})
export default class UnlockOfferButton extends Vue {
    @Action('projectLoadingState/updateAreOffersLoading')
    private updateAreOffersLoading!: (newState: boolean) => void;

    @Prop({ default: null }) private selectedOfferId!: string | null;

    private async unlockOffer() {
        if (this.selectedOfferId == null) {
            return;
        }

        this.updateAreOffersLoading(true);

        try {
            await Offer.unlockOffer(this.selectedOfferId);
        } finally {
            this.updateAreOffersLoading(false);
        }

        EventBus.$emit(EventBusEvents.updateProject);
    }
}
